import React from "react";
import Style from "./mobileHeader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageMenu from "./languageMenu";
import BeppoLogo from "../../common/assets/beppo-logo-119x47.svg";

interface MobileHeaderProps {
  logoHeader: boolean;
  arrowVisible: boolean;
}

const MobileHeader = (props: MobileHeaderProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();
  const menu = () => {
    setOpenMenu(!openMenu);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };
  const changeLanguageLabel = (label: string) => {
    setLanguage(label);
  };

  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n?.language?.toUpperCase());

  return (
    <div className={Style.headerContainerWithLogo}>
      <div className={Style.headerContainerWithLogo__imgContainer}>
        <img src={BeppoLogo} id="beppoLogo" alt="beppo-logo" width={"100"} />
      </div>
      <div onClick={menu} className={Style.languageSwitchContainer}>
        <div className={Style.languageSwitchContainer__currentLanguage}>
          <FontAwesomeIcon icon={openMenu ? faAngleUp : faAngleDown} />
          <div>{language}</div>
        </div>
        {openMenu && (
          <LanguageMenu
            closeMenu={closeMenu}
            changeLanguageLabel={changeLanguageLabel}
          />
        )}
      </div>
    </div>
  );
};

MobileHeader.defaultProps = {
  arrowVisible: false,
  logoHeader: false,
};
export default MobileHeader;
